import React from 'react';
import PhoneInput from 'react-phone-number-input/input';
import CSSModules from 'react-css-modules';

import styles from './styles.scss';

const PhoneField = ({ field, form, ...props}) => {
    const className = form.errors[field.name] && form.touched[field.name] ? 'has-error' : '';

    return (
        <PhoneInput
            international={false}
            styleName="phone-input"
            className={className}
            onChange={value => {
                if (!form.touched[field.name]) form.setFieldTouched(field.name);
                form.setFieldValue(field.name, value);
            }}
            country="US"
        />
    );
};

// export default PhoneField

export default CSSModules(PhoneField, styles);
