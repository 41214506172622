import {
	SAVING_FORM_ENTRY,
	SAVED_FORM_ENTRY,
	REQUESTING_FORM_ENTRY,
	RECEIVED_FORM_ENTRY,
	RESET_FORM,
	REQUESTING_CITY,
	RECEIVED_CITY,
	SET_EFTID,
	REQUESTING_TCPA,
	RECEIVED_TCPA
} from './actions';

const initialState = {
	complete: false,
	oid: '',
	affid: '',
	efTid: '',
	trusted_form_cert_id: '',
	lp_s1: '',
	lp_s2: '',
	lp_s3: '',
	lp_s4: '',
	lp_s5: '',
	source_id: '',
	sending: false,
	sessionId: '',
	currentStep: 0,
	prevSteps: [0, ],
	incrementStep: 1,
	lp_campaign_id: '',
	lp_campaign_key: '',
	medicare_status: '',
	first_name: '',
	last_name: '',
	dob: {day: '', month: '', year: ''},
	address: '',
	zip_code: '',
	city: '',
	state: '',
	country: '',
	gender: '',
	phone: '',
	email: '',
	jornaya_lead_id: '',
	ip_address: '',
	tcpa: ''
	// response: {
	// 	result: "",
	// 	lead_id: "",
	// 	price: ""
	// }
}

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SAVING_FORM_ENTRY:
		case REQUESTING_FORM_ENTRY:
		case REQUESTING_CITY:
		case REQUESTING_TCPA:
			return Object.assign({}, state, {
				...state,
				sending: true,
			});
		case SAVED_FORM_ENTRY:
		case RECEIVED_FORM_ENTRY:
			const tcpa = state.tcpa;

			return Object.assign({}, state, {
				sending: false,
				response: action.response,
				...action.data,
				tcpa: tcpa
			});
		case RESET_FORM:
			return initialState;
		case RECEIVED_CITY:
			return Object.assign({}, state, {
				...state,
				sending: false,
				city: action.city,
				state: action.state
			});
		case SET_EFTID:
			return Object.assign(({}, state, {
				...state,
				efTid: action.efTid
			}));
		case RECEIVED_TCPA:
			return Object.assign(({}, state, {
				...state,
				tcpa: action.tcpa
			}));
		default:
			return state;
	}
};
