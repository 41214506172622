export const REQUEST_ADDITIONAL_PROVIDERS = 'REQUEST_ADDITIONAL_PROVIDERS';
export const RECEIVE_ADDITIONAL_PROVIDERS = 'RECEIVE_ADDITIONAL_PROVIDERS';


const requestAdditionalProviders = () => {
    return {
        type: REQUEST_ADDITIONAL_PROVIDERS
    };
}

const receiveAdditionalProviders = (data) => {
    return {
        type: RECEIVE_ADDITIONAL_PROVIDERS,
        data
    };
}



export { receiveAdditionalProviders, requestAdditionalProviders };

