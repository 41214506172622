import React from 'react';
import CSSModules from 'react-css-modules';

import styles from './styles.scss';

const Steps = props => {
	const {
		stepNumber,
		goBack,
		values
	} = props

	return (
		<div className="row">
		<div styleName="steps">
			{ stepNumber > 0
				&& <button type="button" className="btn btn-link go-back secondary" onClick={() => goBack(values) }>
					&larr; Previous Question
				</button>
			}
		</div>
		</div>
	);
};

export default CSSModules(Steps, styles);
