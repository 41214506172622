import React, { Fragment, useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { saveFormEntry, getFormEntry, resetFormData } from '../../data/formEntry/actions';

import FormWizard from '../../components/FormWizard';
import MedicareStatus from "../../components/MedicareStatus";
import BirthMonth from '../../components/BirthMonth';
import BirthDay from '../../components/BirthDay';
import BirthYear from '../../components/BirthYear';
import ZipCode from '../../components/ZipCode';
import Gender from '../../components/Gender';
import useQuery from "../../utils/useQuery";

import GetMyQuote from '../../components/GetMyQuote';


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));


const quoteForm = props => {
	// const { stepNumber } = props.match.params;
	const { formEntry, saveEntry, getEntry, history, resetFormData } = props;
	const jornayaRef = React.createRef();
	const query = useQuery();
	const zipCode = query.get('zip_code');
	const [dynamicZip, setDynamicZip] = useState(false);

	const initialValues = formEntry;

	useEffect(() => {
		if (zipCode) {
			initialValues['zip_code'] = zipCode;
			setDynamicZip(true);
		}
	}, []);

	return (
		<Fragment>
			<FormWizard
				initialValues={formEntry}
				onSubmit={async values =>
		        	sleep(300).then(() => console.log("submit"))
		      	}
		      	initialStepNumber={formEntry.currentStep}
		      	prevSteps={formEntry.prevSteps}
		      	history={history}
		      	incrementStep={formEntry.incrementStep}
		      	saveEntry={saveEntry}
		      	getEntry={getEntry}
		      	resetFormData={resetFormData}
		      	jornayaRef={jornayaRef}
		    >
				<MedicareStatus />
				<BirthMonth />
				<BirthDay />
				<BirthYear />
				<Gender />
				{ !dynamicZip && <ZipCode />  }
				<GetMyQuote />

		    </FormWizard>
		    <input
		    	ref={jornayaRef}
				id="leadid_token"
				name="universal_leadid"
				type="hidden"
				value=""
			/>
		</Fragment>
	)
};

const mapStateToProps = state => {
	const { formEntry } = state.data;

	return {
		formEntry
	}
}

const mapDispatchToProps = dispatch => {
	return {
		saveEntry: (formData, currentStep, prevStep, completed) => dispatch(saveFormEntry(formData, currentStep, prevStep, completed)),
		getEntry: (id) => dispatch(getFormEntry(id)),
		resetFormData: () => dispatch(resetFormData())
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(quoteForm));

